import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import App from "./App";
import Authentication from "./features/Authentication";
import { Route, Switch } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import { federated } from "./aws-exports";
import ModuleVersion from "./features/system-version/ModuleVersion";
import { OwcThemeProvider } from "@one/react";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-074147}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{F._Hoffmann-La_Roche_AG}_is_granted_a_{Multiple_Applications}_Developer_License_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_February_2026}____[v3]_[01]_MTc3MTAyNzIwMDAwMA==1769dd331ed2aeb5d1f4bcbc0d4cb136"
);
/**
 * to turn off google login button - remove federated authenticatorProps below
 */
const AuthenticatedApp = withAuthenticator(App, { federated: federated });

const Main = () => {
  return (
    <OwcThemeProvider theme="cobas_light">
      <Switch>
        <Route
          exact
          path={[
            "/",
            "/bookings",
            "/bookings/checkIn/:EventID",
            "/scan",
            "/instrumentDetailedInfo/:inventoryId?",
            "/admin",
            "/equipmentList/:InventoryId",
            "/notifications",
          ]}
        >
          <Authentication>
            <AuthenticatedApp />
          </Authentication>
        </Route>
        <Route exact path="/info">
          <ModuleVersion />
        </Route>
        <Route path="*">
          <NotFoundScreen redirectPath={"/"} />
        </Route>
      </Switch>
    </OwcThemeProvider>
  );
};
export default Main;
