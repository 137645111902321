import React, { useRef } from "react";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import { signOut } from "../utils/signout";
import IconForApp from "../icons/IconForApp";
import { displayNameSelector } from "../features/user/redux/selectors";
import AppSwitcher from "./AppSwitch";
import { OwcButton, OwcDoubleGlobalArea, OwcIconButton, OwcPopover, OwcTypography } from "@one/react";
import styles from "./AppBar.module.scss";
import { NotificationCounter } from "@digitallab/grid-common-components";
import { useHistory, useLocation } from "react-router-dom";

const Appbar = ({ client, landingPageAppSyncClient }) => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((store) => store.user);
  const userId = useSelector((state) => state.user.user);
  const name = useSelector((store) => displayNameSelector(store));
  const email = user.email;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotificationViewAll = () => {
    history.push("/notifications");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const logoLetter = email?.charAt(0);
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const popoverElement = useRef(null);
  return (
    <>
      <OwcDoubleGlobalArea>
        <div slot="primary" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconForApp />
            <OwcTypography style={{ paddingLeft: "16px" }} variant="subtitle1">
              Equipment Booking
            </OwcTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OwcTypography variant="caption">{name}</OwcTypography>
            <OwcIconButton
              data-testid="app-bar-user-info-user"
              id="popover-anchor-for-top-bar"
              hasFocus={anchorEl !== null}
              onClick={handleClick}
              style={{ marginRight: "8px" }}
              flat
              icon="user"
            />

            <AppSwitcher data-testid="app-bar-app-switcher" client={client} />
            <NotificationCounter
              handleNotificationViewAll={handleNotificationViewAll}
              landingPageClient={landingPageAppSyncClient}
              isNotificationHighlighted={location.pathname.indexOf("notification") === 1}
              userId={userId}
            />
            <OwcIconButton
              style={{ marginRight: "26px" }}
              flat
              data-testid="help-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
            />
            <OwcIconButton
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px",
              }}
              icon="roche_logo"
              flat
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>
      <OwcPopover
        ref={popoverElement}
        data-testid="popover-user-menu"
        anchor="popover-anchor-for-top-bar"
        id="popover-anchor-for-top-bar-popover"
        anchor-position="bottom-start"
        visible={open}
        disable-portal={true}
        onClickOutside={() => {
          handleClose();
        }}
        className={styles.appbarPopoverWidth}
      >
        <div className={styles.UserInfoSection}>
          <span className={styles.LogoLetter}>{logoLetter}</span>
          <OwcTypography variant="subtitle1">{name}</OwcTypography>
          <OwcTypography variant="body2">{email}</OwcTypography>
        </div>

        <div className={styles.appbarLogoutContainer}>
          <OwcButton
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
            variant="secondary"
          >
            Logout
          </OwcButton>
        </div>
      </OwcPopover>
    </>
  );
};

export default withApollo(Appbar);
